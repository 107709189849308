<template>
  <div class="container">
    <div class="content">
      <div class="nav">激活状态</div>
      <img src="../../static/fail.png">
      <div class="fail_div">激活失败,请重试</div>
    </div>
    <div class="footer">
      <span class="end" @click="go()">返回&nbsp;登录</span>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    go() {
      this.$router.push('/Userlogin');
    }
  }
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

.container {
  width: 100vw;
  height: 100vh;
}
.nav {
  width: 100%;
  height: 12.5vw;
  line-height: 12.5vw;
  font-size: 4.8vw;
  color: #fff;
  text-align: center;
  background-color: #da251c;
  position: absolute;
  top: 0;
}
.content {
  display: flex;
  height: 50.9vh;
  align-items: center;
  justify-content: center;
  font-size: 4vw;
  flex-direction: column;
}
img {
  width: 19.6vw;
  height: 19.6vw;
}
.fail_div {
  color:#000;
  margin-top: 6.67vh;
  font-size: 4vw;
}
.footer {
  position: absolute;
  width: 100%;
  height: 49.15vh;
  background-image: url("../../static/wangbg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 0 10vw;
}

.end {
	display: block;
	text-decoration: none;
  width: 63.3vw;
  height: 6vh;
  background-image: url("../../static/denglu.png");
  background-size: 100% 100%;
  font-size: 4vw;
  color: #fff;
  text-align: center;
  line-height: 6vh;
  margin-left: 7.7vw;
  cursor: pointer;
}
</style>
